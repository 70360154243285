import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

import clsx from "clsx";
import { BsTrashFill } from "react-icons/bs";

import { WEEKDAYS } from "~/constants/coupons";
import { baseExtendedCoupon } from "~/utils/constants/coupons";
import { transformDate } from "~/utils/coupons/utils";
import { CODE_TO_COUNTRY, COUNTRY_CODES } from "~/utils/data/constants";
import type { CreateEditCoupon } from "~/utils/interfaces/coupons";
import type { Locale } from "~/utils/interfaces/Locale";

type CouponFormProps = {
  startingValues?: CreateEditCoupon;
};

const CouponForm = forwardRef(function CouponForm({ startingValues }: CouponFormProps, _ref) {
  const [couponData, setCouponData] = useState<CreateEditCoupon>(startingValues || baseExtendedCoupon);
  const [currentTag, setCurrentTag] = useState<string>("");
  const [currentSource, setCurrentSource] = useState<string>("");
  const [hasLimitedUses, setHasLimitedUses] = useState<boolean>(!!couponData.remaining_uses);
  const [onlyFirstPurchase, setOnlyFirstPurchase] = useState<boolean>(!!couponData.only_for_first_purchase);
  const [hasMaxDiscount, setHasMaxDiscount] = useState<boolean>(false);
  const [percentageDiscount, setPercentageDiscount] = useState<number>(couponData.discount * 100);

  useEffect(() => {
    if (couponData.method === "percentage") {
      setPercentageDiscount(couponData.discount * 100);
    }
  }, [couponData]);

  useImperativeHandle(_ref, () => ({
    getCouponData: () => ({
      ...couponData,
      discount: couponData.discount,
      begin_date: couponData.begin_date ? transformDate(couponData.begin_date) : null,
      expiration_date: couponData.expiration_date ? transformDate(couponData.expiration_date) : null,
      remaining_uses: hasLimitedUses ? couponData.remaining_uses : null,
      only_for_first_purchase: onlyFirstPurchase,
    }),
  }));

  return (
    <div className="grid grid-cols-2 gap-4 w-full">
      <div className="col-span-full flex flex-col gap-y-1">
        <div className="text-lg font-light">Código</div>
        <input
          type="text"
          id="code"
          className={clsx(
            "w-full",
            "bg-examedi-light-gray-bg",
            "border border-examedi-gray-light",
            "text-sm",
            "rounded-lg",
            "focus:ring-examedi-blue-normal focus:border-examedi-blue-normal",
            "block",
            "p-2.5",
          )}
          placeholder="Ingresa el código"
          value={couponData.referral_code}
          onChange={(e) => {
            setCouponData((prev) => ({
              ...prev,
              referral_code: e.target.value.toUpperCase().trim(),
            }));
          }}
        />
      </div>
      <div className="col-span-full flex flex-col gap-y-1">
        <div className="text-lg font-light">País donde se aplicará</div>
        <select
          placeholder="País"
          id="countries"
          value={couponData.country}
          onChange={(e) => {
            setCouponData((prev) => ({
              ...prev,
              country: e.target.value as Locale,
            }));
          }}
          className={clsx(
            "w-full",
            "border border-gray-300",
            "text-sm",
            "rounded-lg",
            "focus:ring-blue-500 focus:border-blue-500",
            "block",
            "p-2.5",
          )}
        >
          {COUNTRY_CODES.map((code) => (
            <option
              key={`country-${code}`}
              value={code}
            >
              {CODE_TO_COUNTRY[code]}
            </option>
          ))}
        </select>
      </div>
      <div className="col-span-full flex flex-col gap-y-1">
        <div className="text-lg font-light">Valor del cupón</div>
        <div className="flex gap-x-4">
          <select
            id="type"
            value={couponData.method}
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                method: e.target.value as "percentage" | "amount",
                discount: 0,
              }));
            }}
            className={clsx(
              "w-full p-2.5",
              "text-sm",
              "border border-gray-300",
              "rounded-lg",
              "focus:ring-blue-500 focus:border-blue-500",
              "block",
            )}
          >
            <option value="percentage">Porcentaje</option>
            <option value="amount">Monto</option>
          </select>
          <input
            id="amount"
            type="number"
            className={clsx(
              "w-full block p-2.5",
              "bg-examedi-light-gray-bg border border-examedi-gray-light",
              "text-sm",
              "rounded-lg",
              "focus:ring-examedi-blue-normal focus:border-examedi-blue-normal",
            )}
            placeholder="Descuento"
            min={0}
            max={couponData.method === "percentage" ? 100 : undefined}
            value={couponData.method === "percentage" ? percentageDiscount : couponData.discount}
            onChange={(e) => {
              if (couponData.method === "percentage") {
                setCouponData((prev) => ({
                  ...prev,
                  discount: parseInt(e.target.value) / 100,
                }));
              }
              if (couponData.method === "amount") {
                setCouponData((prev) => ({
                  ...prev,
                  discount: e.target.value as unknown as number,
                }));
              }
            }}
          />
          {couponData.method === "percentage" && <div className="self-center font-bold text-sm ml-1">%</div>}
        </div>
      </div>
      <div className="col-span-full flex flex-col gap-y-1">
        <div className="text-lg font-light">Alianza</div>
        <input
          type="text"
          id="code"
          className={clsx(
            "w-full",
            "bg-examedi-light-gray-bg",
            "border border-examedi-gray-light",
            "text-sm",
            "rounded-lg",
            "focus:ring-examedi-blue-normal focus:border-examedi-blue-normal",
            "block",
            "p-2.5",
          )}
          placeholder="Alianza"
          value={couponData.alliance}
          onChange={(e) => {
            setCouponData((prev) => ({
              ...prev,
              alliance: e.target.value.trim(),
            }));
          }}
        />
      </div>
      <h5 className="col-span-full">Reglas del cupón</h5>
      <div className="flex flex-row items-center gap-2 w-full">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={onlyFirstPurchase}
          onChange={(e) => setOnlyFirstPurchase(e.target.checked)}
        />
        <span className="w-full">Solo para primera compra</span>
      </div>
      <div></div>
      <div className="flex flex-row items-center gap-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={hasLimitedUses}
          onChange={(e) => setHasLimitedUses(e.target.checked)}
        />
        <span>Usos limitados</span>
      </div>
      <div>
        {hasLimitedUses && (
          <input
            id="useLimit"
            type="number"
            className={clsx(
              "w-full",
              "bg-examedi-light-gray-bg",
              "border",
              "border-examedi-gray-light",
              "text-sm",
              "rounded-lg",
              "focus:ring-examedi-blue-normal",
              "focus:border-examedi-blue-normal",
              "block",
              "p-2.5",
            )}
            min={0}
            placeholder="Cantidad de usos"
            value={couponData.remaining_uses || 0}
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                remaining_uses: +e.target.value,
              }));
            }}
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={hasMaxDiscount}
          onChange={(e) => setHasMaxDiscount(e.target.checked)}
        />
        <span>Descuento máximo</span>
      </div>
      <div>
        {hasMaxDiscount && (
          <input
            type="number"
            id="code"
            className="w-full bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            placeholder="MONTO"
            value={couponData.max_discount || 0}
            min={0}
            step="1000"
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                max_discount: +e.target.value,
              }));
            }}
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={couponData.minimum_purchase >= 1}
          onChange={() => {
            setCouponData((prev) => ({
              ...prev,
              minimum_purchase: prev.minimum_purchase >= 1 ? 0 : 1000,
            }));
          }}
        />
        <span>Compra mínima</span>
      </div>
      <div>
        {couponData.minimum_purchase >= 1 && (
          <input
            type="number"
            id="code"
            className="w-full bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            placeholder="MONTO"
            value={couponData.minimum_purchase}
            step="1000"
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                minimum_purchase: +e.target.value,
              }));
            }}
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={couponData.begin_date !== null}
          onChange={() => {
            setCouponData((prev) => ({
              ...prev,
              begin_date: prev.begin_date === null ? new Date().toISOString().split("T")[0] : null,
            }));
          }}
        />
        <span>Fecha de inicio</span>
      </div>
      <div>
        {couponData.begin_date !== null && (
          <input
            type="date"
            id="code"
            className="w-full bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            value={couponData.begin_date.substring(0, 10)}
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                begin_date: e.target.value,
              }));
            }}
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={couponData.expiration_date !== null}
          onChange={() => {
            setCouponData((prev) => ({
              ...prev,
              expiration_date: prev.expiration_date === null ? new Date().toISOString().split("T")[0] : null,
            }));
          }}
        />
        <span>Fecha de expiración</span>
      </div>
      <div>
        {couponData.expiration_date !== null && (
          <input
            type="date"
            id="code"
            className="w-full bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            value={couponData.expiration_date.substring(0, 10)}
            onChange={(e) => {
              setCouponData((prev) => ({
                ...prev,
                expiration_date: e.target.value,
              }));
            }}
          />
        )}
      </div>
      <h5 className="col-span-full">Habilitado los dias:</h5>
      <div className="w-full grid gap-y-5">
        {Object.entries(WEEKDAYS).map(([key, value]) => (
          <div className="flex items-center gap-2">
            <input
              id={key}
              type="checkbox"
              className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
              checked={!couponData.disabled_days.includes(key)}
              onChange={(e) => {
                setCouponData((prev) => {
                  if (e.target.checked) {
                    const index = prev.disabled_days.indexOf(key);
                    return {
                      ...prev,
                      disabled_days: [...prev.disabled_days.slice(0, index), ...prev.disabled_days.slice(index + 1)],
                    };
                  } else {
                    return {
                      ...prev,
                      disabled_days: [...prev.disabled_days, key],
                    };
                  }
                });
              }}
            />
            <label
              htmlFor={key}
              className="capitalize"
            >
              {value}
            </label>
          </div>
        ))}
      </div>
      <div className="w-full col-start-1">
        sales_sources incluidos:
        <ul className="list-disc">
          {couponData.sources.map((source) => (
            <li key={source}>
              {source}
              {"  "}
              <BsTrashFill
                className="inline text-examedi-red-error"
                onClick={() => {
                  setCouponData((prev) => ({
                    ...prev,
                    sources: prev.sources.filter((word) => word !== source),
                  }));
                }}
              />
            </li>
          ))}
        </ul>
      </div>
      <div>
        <div className="flex flex-row gap-2">
          <input
            type="text"
            className="bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
            placeholder="SALES_SOURCE"
            value={currentSource}
            onChange={(e) => {
              setCurrentSource(e.target.value);
            }}
          />
          <button
            className="bg-examedi-blue-normal text-examedi-white-pure text-sm rounded-lg block p-2.5"
            onClick={() => {
              setCouponData((prev) => ({
                ...prev,
                sources: [...prev.sources, currentSource],
              }));
              setCurrentSource("");
            }}
          >
            AÑADIR
          </button>
        </div>
      </div>
      <h5 className="col-span-2">Servicios</h5>
      <div className="flex flex-row items-center gap-2 col-span-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={couponData.telemedicine}
          onChange={() =>
            setCouponData((prev) => ({
              ...prev,
              telemedicine: !prev.telemedicine,
            }))
          }
        />
        <span>Telemedicina</span>
      </div>
      <div className="flex flex-row items-center gap-2 col-span-2">
        <input
          id="default-checkbox"
          type="checkbox"
          className="w-5 h-5 text-examedi-blue-normal bg-examedi-light-gray-bg border-examedi-gray-light"
          checked={couponData.global_discount}
          onChange={() =>
            setCouponData((prev) => ({
              ...prev,
              global_discount: !prev.global_discount,
            }))
          }
        />
        <span>Todos los servicios a domicilio</span>
      </div>
      <div>
        {!couponData.global_discount && (
          <div>
            Solo para tags incluidos:
            <ul className="list-disc">
              {couponData.tags.map((tag) => (
                <li key={tag}>
                  {tag}
                  {"  "}
                  <BsTrashFill
                    className="inline text-examedi-red-error"
                    onClick={() => {
                      setCouponData((prev) => ({
                        ...prev,
                        tags: prev.tags.filter((word) => word !== tag),
                      }));
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div>
        {!couponData.global_discount && (
          <div className="flex flex-row gap-2">
            <input
              type="text"
              className="bg-examedi-light-gray-bg border border-examedi-gray-light text-sm rounded-lg focus:ring-examedi-blue-normal focus:border-examedi-blue-normal block p-2.5"
              placeholder="TAG"
              value={currentTag}
              onChange={(e) => {
                setCurrentTag(e.target.value);
              }}
            />
            <button
              className="bg-examedi-blue-normal text-examedi-white-pure text-sm rounded-lg block p-2.5"
              onClick={() => {
                setCouponData((prev) => ({
                  ...prev,
                  tags: [...prev.tags, currentTag],
                }));
                setCurrentTag("");
              }}
            >
              AÑADIR
            </button>
          </div>
        )}
      </div>
    </div>
  );
});

CouponForm.displayName = "CouponForm";

export default CouponForm;
