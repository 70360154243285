import type { CreateEditCoupon } from "~/utils/interfaces/coupons";

export const baseExtendedCoupon: CreateEditCoupon = {
  referral_code: "",
  method: "percentage",
  discount: 0,
  remaining_uses: null,
  max_discount: null,
  country: "cl",
  global_discount: true,
  begin_date: null,
  expiration_date: null,
  minimum_purchase: 0,
  tags: [],
  telemedicine: false,
  excluded_items: [],
  sources: [],
  disabled_days: [],
  only_for_first_purchase: false,
};
